"use client";

// Error components must be Client Components
import GlobalErrorBoundary from "@faire/retailer-visitor-shared/GlobalErrorBoundary";
import React from "react";

const GlobalError = ({ error }: { error: Error }) => {
  return (
    <html>
      <body>
        <GlobalErrorBoundary error={error} />
      </body>
    </html>
  );
};

export default GlobalError;
